// UI Name
export const AZ_PICK_APP_NAME = 'AZPick_V2_UI';

// Configuration
export const PICK_TOUR_PAGINATION_PAGE_SIZE = 10;

// App Page Names
export const APP_PAGE_ORDER_LIST = 'orderList';
export const APP_PAGE_PICK_TOUR = 'pickTour';

// Pick Tour Page Names
export const PICK_TOUR_PAGE_PICK_TOUR = 'pickTour';
export const PICK_TOUR_PAGE_COMMERCIAL_SKIP = 'commercialSkip';
export const PICK_TOUR_PAGE_COMPLETE = 'complete';
export const PICK_TOUR_PAGE_EXIT = 'exit';
export const PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED = 'confirmExit';
export const PICK_TOUR_PAGE_EXIT_REVIEW = 'exitReview';
export const PICK_TOUR_PAGE_ITEM_VALIDATION = 'pickTourItemValidation';
export const PICK_TOUR_PAGE_UPDATE_FAILED = 'updateFailed';

// Order Types
export const ORDER_TYPE_SAT_TO_SAT = 'SAT2SAT';
export const ORDER_TYPE_COMMERCIAL = 'COMMERCIAL';
export const ORDER_TYPE_COMMERCIAL_EP = 'COMMERCIALEP';
export const ORDER_TYPE_OVERSTOCK = 'OVERSTOCK';
export const ORDER_TYPE_RECALL = 'RECALL';

// Special Order IDs
export const ORDER_ID_OVERSTOCK_ORDER_GENERATE_PCKTOUR = 'OVERSTOCK_ORDER_GENERATE_PCKTOUR';
export const ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR = 'OVERSTCK_GENERATE_MANUAL_PCKTOUR';

// Media Query Breakpoints
export const MQ_MAX_WIDTH = 768;
export const MQ_MIN_WIDTH = 769;

// Saga Names
export const SAGA_ADJUST_SLOT_QTY = 'adjustSlotQty';
export const SAGA_CREATE_PICK_TOUR = 'createPickTour';
export const SAGA_FORWARD_ORDERS = 'forwardOrders';
export const SAGA_GET_EMPLOYEE_METADATA = 'getEmployeeMetadata';
export const SAGA_GET_EXTERNAL_CONFIG = 'getExternalConfig';
export const SAGA_GET_ORDERS = 'getOrders';
export const SAGA_GET_RECALL_ORDERS = 'getRecallOrders';
export const SAGA_GET_COMPLETED_RECALLS = 'getCompletedRecalls';
export const SAGA_GET_PICK_TOUR_ITEMS = 'getPickTourItems';
export const SAGA_GET_PROXIMITY_STORE = 'getProximityStore';
export const SAGA_SKIP_PICK_TOUR = 'skipPickTour';
export const SAGA_UPDATE_PICK_TOUR = 'updatePickTour';
export const SAGA_UPDATE_PICK_TOUR_ITEM = 'updatePickTourItem';
export const SAGA_RETRY_ORDER = 'retryOrder';
export const SAGA_PRINT_PICK_LIST = 'printPickList';
export const SAGA_PRINT_LOCATION_REPORT = 'printLocationReport';
export const SAGA_PRINT_DISCREPANCY_REPORT = 'printDiscrepancyReport';
export const SAGA_PRINT_RECALL_REPORT = 'printRecallReport';
export const SAGA_NAMES = [
  SAGA_ADJUST_SLOT_QTY,
  SAGA_CREATE_PICK_TOUR,
  SAGA_FORWARD_ORDERS,
  SAGA_GET_EMPLOYEE_METADATA,
  SAGA_GET_EXTERNAL_CONFIG,
  SAGA_GET_ORDERS,
  SAGA_GET_RECALL_ORDERS,
  SAGA_GET_COMPLETED_RECALLS,
  SAGA_GET_PICK_TOUR_ITEMS,
  SAGA_GET_PROXIMITY_STORE,
  SAGA_SKIP_PICK_TOUR,
  SAGA_UPDATE_PICK_TOUR,
  SAGA_UPDATE_PICK_TOUR_ITEM,
  SAGA_RETRY_ORDER,
  SAGA_PRINT_PICK_LIST,
  SAGA_PRINT_LOCATION_REPORT,
  SAGA_PRINT_DISCREPANCY_REPORT,
  SAGA_PRINT_RECALL_REPORT,
] as const;

// API Call Paths
export const BFF_EXTERNAL_CONFIG = '/maintenance/configs';
export const BFF_ROOT = '/external/bff/inventory/unified-picking';
export const BFF_V1 = '/v1';
export const BFF_ORDERS = '/orders';
export const BFF_RECALL_ORDERS_QUERY_PARAM = '?orderType=RECALL';
export const BFF_PICK_TOURS = '/pick-tours';
export const BFF_STORES = '/stores';
export const BFF_FORWARD = '/forward-items';
export const BFF_SKIP = '/skip-items';
export const BFF_UPDATE = '/update';
export const BFF_ITEMS = '/items';
export const BFF_USERS = '/users';
export const BFF_SLOTS = '/slots';
export const BFF_COMPLETED_RECALLS = '/completed-recalls';
export const PRINT_PICK_TOUR = '/print-pick-tour';
export const RETRY_FINALIZATION = '/retry-finalization';
export const ENTERPRISE_PROXIMITY_REQUEST = '/enterprise/store/v1/proximity-request';
export const PRINT_LOCATION_REPORT = '/print-overstock-location-report';
export const PRINT_DISCREPANCY_REPORT = '/print-overstock-discrepancy-report';
export const PRINT_RECALL_REPORT = '/print-recall-report';
export const PRINT_RECALL_LABELS = '/print-recall-labels';
export const DRIVER_LOGOFF = '/external/integrations/dispatching-routing/v1/driver-logoff';

// Skip Reasons
export const QUANTITY_ON_HAND = 'QUANTITY_ON_HAND';
export const OTHER = 'OTHER';

// Pick Tour Status
export const PICK_TOUR_STATUS_CLOSED = 'CLOSED';
export const PICK_TOUR_STATUS_OPEN = 'OPEN';
export const PICK_TOUR_SUB_STATUS_SKIPPED = 'SKIPPED';
export const PICK_TOUR_SUB_STATUS_PARTFILL = 'PARTFILL';
export const PICK_TOUR_SUB_STATUS_FILLED = 'FILLED';
export const PICK_TOUR_SUB_STATUS_CANCEL = 'CANCEL';
export const PICK_TOUR_SUB_STATUS_PAUSED = 'PAUSED';
export const PICK_TOUR_SUB_STATUS_PROGRESS = 'PROGRESS';

// Special Planogram Names
export const POG_OVERSTOCK = 'OVERSTOCK';
export const POG_DYNAMIC_SLOT = 'DYNAMIC_SLOT';

// Test constants
export const TEST_HEADERS = {
  Authorization: 'Bearer 1234',
  'Content-Type': 'application/json',
  'Accept-Language': 'en',
};

// Api Errors Codes
export const COMMERCIAL_ORDER_SERVICE_DOWN = 'COMMERCIAL_ORDER_SERVICE_DOWN';
export const COMMERCIAL_CUSTOMER_SERVICE_DOWN = 'COMMERCIAL_CUSTOMER_SERVICE_DOWN';
export const CUSTOMER_FILE_SERVICE_DOWN = 'CUSTOMER_FILE_SERVICE_DOWN';
export const EDFS_SERVICE_DOWN = 'EDFS_SERVICE_DOWN';
export const GENERIC_NON_SERVICE_ERROR = 'GENERIC_NON_SERVICE_ERROR';
export const INVALID_PICKTOUR = 'INVALID_PICKTOUR';
export const ITEM_SERVICE_DOWN = 'ITEM_SERVICE_DOWN';
export const NO_ITEMS_NO_PRINT = 'NO_ITEMS_NO_PRINT';
export const NO_OVERSTOCK_LOCATIONS = 'NO_OVERSTOCK_LOCATIONS';
export const NO_OVERSTOCK_ORDERS_GENERATED = 'NO_OVERSTOCK_ORDERS_GENERATED';
export const ORDERTYPE_NOT_ENABLED = 'ORDERTYPE_NOT_ENABLED';
export const OVERSTOCK_PICKING_NOT_ENABLED = 'OVERSTOCK_PICKING_NOT_ENABLED';
export const PICKTOUR_CLOSED = 'PICKTOUR_CLOSED';
export const PICKTOUR_NOT_AVAILABLE = 'PICKTOUR_NOT_AVAILABLE';
export const PLANOGRAM_SERVICE_DOWN = 'PLANOGRAM_SERVICE_DOWN';
export const PREVIOUS_OVERSTOCK_TOUR_PENDING = 'PREVIOUS_OVERSTOCK_TOUR_PENDING';
export const PRINTING_SERVICE_DOWN = 'PRINTING_SERVICE_DOWN';
export const REPLENISHMENT_ORDER_SERVICE_DOWN = 'REPLENISHMENT_ORDER_SERVICE_DOWN';
export const SAT2SAT_PICKING_NOT_ENABLED = 'SAT2SAT_PICKING_NOT_ENABLED';
export const SMART_SOURCING_SERVICE_DOWN = 'SMART_SOURCING_SERVICE_DOWN';
export const ORDER_SERVICE_ERROR = 'ORDER_SERVICE_ERROR';
export const STORE_INVENTORY_MANAGEMENT_SERVICE_DOWN = 'STORE_INVENTORY_MANAGEMENT_SERVICE_DOWN';
export const CENTRALIZED_INVENTORY_SERVICE_DOWN = 'CENTRALIZED_INVENTORY_SERVICE_DOWN';
export const PRINT_SERVICE_ERROR = 'PRINT_SERVICE_ERROR';
export const RECALL_ERROR = 'RECALL_ERROR';
export const COMMERCIAL_ORDER_SERVICE_DOWN_REPLENISHMENT_ORDER_SERVICE_DOWN =
  'COMMERCIAL_ORDER_SERVICE_DOWN_REPLENISHMENT_ORDER_SERVICE_DOWN';
export const REPLENISHMENT_ORDER_SERVICE_DOWN_COMMERCIAL_ORDER_SERVICE_DOWN =
  'REPLENISHMENT_ORDER_SERVICE_DOWN_COMMERCIAL_ORDER_SERVICE_DOWN';
// Session Storage Objects
export const USER_DATA = 'userData';
export const PROXIMITY_SERVICE_ERROR = 'PROXIMITY_SERVICE_ERROR';

// Device Types
export const DESKTOP = 'Desktop';
export const CT40 = 'CT40';
export const TC75 = 'TC75';
export const ANDROID = 'Android';

// API Fetch status
export const API_FETCH_READY = 'API_FETCH_READY';
export const API_FETCH_PENDING = 'API_FETCH_PENDING';
export const API_FETCH_FULLFILLED = 'API_FETCH_FULLFILLED';
export const API_FETCH_ERROR = 'API_FETCH_ERROR';

// Memory
export const BYTE = 1024;

// Flags
export const SHOULD_UPDATE_PICK_TOUR_SUB_STATUS = 'shouldUpdatePickTourSubStatus';
export const TRUE = 'TRUE';
export const FALSE = 'FALSE';

// Local Storage
export const STORE_RECALL_DATA = 'storeRecallData';
export const VERIFIED_ORDERS = 'verifiedOrders';
export const VERIFIED_RECALLS = 'verifiedRecalls';
export const LAST_LOGOUT = 'lastLogout';
export const LAST_LOGIN = 'lastLogin';
