import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Button from '../../../buttons/Button';

import { updatePickTour, forwardOrders } from '../../../../redux/actions/activePickTour.actions';

import { selectIsSmartSourcingEnabled, getActivePickTourState } from '../../../../redux/selectors';

import { calculateAmountToPick, stripLeadingZeroes, mergeClassNames } from '../../../../utils';

import {
  PICK_TOUR_STATUS_CLOSED,
  PICK_TOUR_SUB_STATUS_PARTFILL,
  PICK_TOUR_SUB_STATUS_SKIPPED,
  ORDER_TYPE_COMMERCIAL_EP,
} from '../../../../constants';

import styles from './CommercialSkipFlow.css';

const CommercialSkipFlow = (): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'COMMERCIAL_SKIP_FLOW';

  const activePickTourState = useSelector(getActivePickTourState);
  const dispatch = useDispatch();

  const isSmartSourcingEnabled = useSelector(selectIsSmartSourcingEnabled);

  if (activePickTourState) {
    const { forwardingStore, pickTour } = activePickTourState;
    const { picks } = pickTour;
    const isEpHub = picks[0].orderType === ORDER_TYPE_COMMERCIAL_EP

    const dispatchUpdatePickTour = () =>
      dispatch(
        updatePickTour({
          updateType: 'finish',
          updateStatus: {
            pickTourStatus: PICK_TOUR_STATUS_CLOSED,
            pickTourSubStatus: picks.some(pick => pick.pickedQuantity !== 0)
              ? PICK_TOUR_SUB_STATUS_PARTFILL
              : PICK_TOUR_SUB_STATUS_SKIPPED,
          },
        })
      );

    return (
      <section className={styles.section}>
        <header className={styles.header}>
          <h3
            className={mergeClassNames(styles.headerText, !isSmartSourcingEnabled ? styles.noSmartSourcingHeader : '')}
          >
            {t(
              `${translationRoot}.${
                // eslint-disable-next-line no-nested-ternary
                isEpHub
                  ? 'SKIPPED_ITEMS'
                  : !isSmartSourcingEnabled
                  ? 'ALTERNATIVE_SOURCE_REQUIRED'
                  : forwardingStore
                  ? 'TRANSFER_STORE'
                  : 'VDP_OR_OUTSIDE'
              }`
            )}
          </h3>
          <article className={styles.article}>
            <p className={styles.p}>
              {// eslint-disable-next-line no-nested-ternary
              isEpHub
                ? t(`${translationRoot}.PICKED_ITEMS_TRANSFERRED`)
                : !isSmartSourcingEnabled
                ? t(`${translationRoot}.SKIPPED_ITEMS_NO_SMART_SOURCING`)
                : forwardingStore
                ? `${t(`${translationRoot}.SKIPPED_ITEMS_AVAILABLE`)} ${stripLeadingZeroes(forwardingStore)}.`
                : t(`${translationRoot}.SKIPPED_ITEMS_NOT_AVAILABLE`)}
            </p>
          </article>
          {isEpHub && (
            <article className={styles.article}>
              <p className={styles.p}>{t(`${translationRoot}.CHECK_PRINTER_META`)}</p>
            </article>
          )}
          <ul className={styles.list}>
            <li className={styles.listHeader}>
              <h5 className={styles.partLabel}>{t('ACTIVE_PICK_TOUR.DETAILS_VIEW.PART_NUMBER')}</h5>
              <h5 className={styles.qtyLabel}>{t('ACTIVE_PICK_TOUR.PICK_TOUR_FOOTER.QTY')}</h5>
            </li>
            {picks
              .filter(pick => pick.isSkipped)
              .map(pick => (
                <li key={pick.orderID} className={styles.listItem}>
                  <span className={styles.part}>{pick.partNumber}</span>
                  <span className={styles.qty}>{calculateAmountToPick(pick) - pick.pickedQuantity}</span>
                </li>
              ))}
          </ul>
        </header>
        <footer className={forwardingStore ? styles.transferFooter : styles.outsideBuyFooter}>
          <Button
            className={forwardingStore ? styles.halfButton : styles.fullButton}
            color={forwardingStore ? 'secondary' : 'primary'}
            onClick={dispatchUpdatePickTour}
          >
            {t(`${translationRoot}.BUTTONS.${isEpHub ? 'CONTINUE' : forwardingStore ? 'MANUAL' : 'CONFIRM'}`)}
          </Button>
          {forwardingStore && (
            <Button className={styles.halfButton} onClick={() => dispatch(forwardOrders({ storeID: forwardingStore }))}>
              {t(`${translationRoot}.BUTTONS.TRANSFER`)}
            </Button>
          )}
        </footer>
      </section>
    );
  }
  return <span>{t(`${translationRoot}.BUTTONS.MISSING_DATA`)}</span>;
};

export default CommercialSkipFlow;
