import React, { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';

import { useTranslation } from 'react-i18next';
import Header from '../../layout/Header';
import Logo from '../../layout/Logo/Logo';
import TourQuantityBox from '../../layout/TourQuantityBox';
import IconButton from '../../buttons/IconButton';
import Main from '../../layout/Main';
import Switch, { Page } from '../../layout/Switch';
import ActivePickTour from './ActivePickTour/ActivePickTour';
import CommercialSkipFlow from './CommercialSkipFlow';
import PickTourComplete from './PickTourComplete';
import ExitPickTour from './ExitPickTour';
import ConfirmStaged from './ExitPickTour/ConfirmStaged';
import PickTourExitReview from './ExitPickTour/PickTourExitReview';
import ItemValidation from '../../layout/ItemValidation';
import ConfirmationScreen from '../../layout/ConfirmationScreen/ConfirmationScreen';
import Aside from '../../layout/Aside';
import ArrowBackIcon from '../../../assets/icons/ArrowBackIcon';
import XIcon from '../../../assets/icons/XIcon';

import { version } from '../../../../package.json';

import {
  getPickTourItems,
  printLocationReport,
  printRecallReport,
  printPickList,
  printDiscrepancyReport,
  skipPickTour,
  updatePickTour,
} from '../../../redux/actions/activePickTour.actions';
import { setAppPage } from '../../../redux/actions/appPage.actions';
import { setPickTourPage } from '../../../redux/actions/pickTourPage.actions';
import { createToast } from '../../../redux/actions/toasts.actions';

import {
  getActivePickTourFromState,
  getPickTourPageNameFromState,
  getActivePickIDFromState,
  getHasJustScannedFromState,
  selectIsTourMixedValidation,
  selectPaginationData,
  selectCurrentLanguage,
  getEmployee,
  selectPickerStoreID,
} from '../../../redux/selectors';

import { ManualEntry, Pick } from '../../../types';
import { UpdateType, UpdateStatus } from '../../../redux/actions/activePickTour.types';

import useWindowSize from '../../../hooks/useWindowSize';
import trimUserName from '../../../utils/trimUserName';

import {
  mergeClassNames,
  calculateAmountToPick,
  calculateTotalQuantity,
  generatePickID,
  calculateResolvedPickPickedQuantity,
  determineDeviceType,
  calculateQuantitiesForExitReview,
  calculatePickedQuantity,
} from '../../../utils';

import {
  APP_PAGE_ORDER_LIST,
  PICK_TOUR_PAGE_PICK_TOUR,
  PICK_TOUR_PAGE_COMMERCIAL_SKIP,
  PICK_TOUR_PAGE_COMPLETE,
  PICK_TOUR_PAGE_EXIT,
  PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED,
  PICK_TOUR_PAGE_EXIT_REVIEW,
  PICK_TOUR_PAGE_ITEM_VALIDATION,
  PICK_TOUR_PAGE_UPDATE_FAILED,
  MQ_MIN_WIDTH,
  MQ_MAX_WIDTH,
  PICK_TOUR_STATUS_CLOSED,
  PICK_TOUR_SUB_STATUS_SKIPPED,
  PICK_TOUR_SUB_STATUS_PARTFILL,
  PICK_TOUR_SUB_STATUS_FILLED,
  PICK_TOUR_STATUS_OPEN,
  ORDER_TYPE_COMMERCIAL,
  ORDER_TYPE_COMMERCIAL_EP,
  ORDER_TYPE_OVERSTOCK,
  PICK_TOUR_SUB_STATUS_PAUSED,
  ORDER_TYPE_RECALL,
} from '../../../constants';

import styles from './PickTourFlow.css';

const PickTourFlow = (): ReactElement | null => {
  const [manualEntryStatus, setManualEntryStatus] = useState<ManualEntry>('closed');
  const [isTourComplete, setIsTourComplete] = useState(false);
  const [isTourStarted, setIsTourStarted] = useState(false);
  const [containerInputValue, setContainerInputValue] = useState('');

  const dispatch = useDispatch();

  const pickTourPage = useSelector(getPickTourPageNameFromState);
  const isTourMixedValidation = useSelector(selectIsTourMixedValidation);
  const activePickTour = useSelector(getActivePickTourFromState);
  const activePickID = useSelector(getActivePickIDFromState);
  const hasJustScanned = useSelector(getHasJustScannedFromState);
  const paginationData = useSelector(selectPaginationData);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const employee = useSelector(getEmployee);
  const pickerStore = useSelector(selectPickerStoreID);

  const { t } = useTranslation();

  const { innerWidth } = useWindowSize();

  const orderType = activePickTour?.picks[0].orderType;

  const endOfTourTranslation = t(`ACTIVE_PICK_TOUR.END_OF_${orderType === 'OVERSTOCK' ? 'OVERSTOCK_' : ''}TOUR`);

  const determinePickTourStatus = (updateType: UpdateType): UpdateStatus => {
    if (updateType === 'finish' && activePickTour) {
      if (orderType === ORDER_TYPE_RECALL) {
        return { pickTourStatus: PICK_TOUR_STATUS_CLOSED, pickTourSubStatus: PICK_TOUR_SUB_STATUS_FILLED };
      }

      if (
        paginationData.isPaginated
          ? paginationData.totalItemsSkipped === paginationData.totalItems
          : activePickTour.picks.every(pick => pick.isSkipped && pick.pickedQuantity === 0)
      ) {
        return { pickTourStatus: PICK_TOUR_STATUS_CLOSED, pickTourSubStatus: PICK_TOUR_SUB_STATUS_SKIPPED };
      }
      if (
        paginationData.isPaginated
          ? paginationData.totalItemsSkipped > 0
          : activePickTour.picks.some(pick => pick.isSkipped)
      ) {
        return { pickTourStatus: PICK_TOUR_STATUS_CLOSED, pickTourSubStatus: PICK_TOUR_SUB_STATUS_PARTFILL };
      }
      return { pickTourStatus: PICK_TOUR_STATUS_CLOSED, pickTourSubStatus: PICK_TOUR_SUB_STATUS_FILLED };
    }
    // exiting a pick tour early
    return { pickTourStatus: PICK_TOUR_STATUS_OPEN, pickTourSubStatus: PICK_TOUR_SUB_STATUS_PAUSED };
  };

  const isFullyPickedOrSkipped =
    (!paginationData.isPaginated || (paginationData.isPaginated && !paginationData.nextPick)) &&
    activePickTour?.picks.every(pick => {
      if (pick.orderType === ORDER_TYPE_RECALL) {
        const updatedQuantity =
          pick.locations.length > 0 ? pick.locations[pick.locations.length - 1].updatedQuantity : null;
        const hasUpdatedQuantity = updatedQuantity !== null && updatedQuantity !== undefined;
        const quantityOnHand = hasUpdatedQuantity ? updatedQuantity : pick.quantityOnHand;
        return (
          pick.pickedQuantity === calculateAmountToPick(pick) ||
          (pick.keepQuantityOnHand !== null &&
            quantityOnHand !== null &&
            quantityOnHand !== undefined &&
            quantityOnHand <= pick.keepQuantityOnHand) ||
          pick.locations.length === 0
        );
      }
      return pick.pickedQuantity === calculateAmountToPick(pick) || pick.isSkipped;
    });

  const closePickTour = (updateType: UpdateType) => {
    dispatch(
      createToast({
        type: 'information',
        message: t(`PICK_TOUR_COMPLETE_OR_EXIT.${updateType === 'finish' ? 'COMPLETE' : 'PAUSED'}`),
        extraData: {
          deviceType: determineDeviceType(),
          pickTourStatus: determinePickTourStatus(updateType),
          orderType,
          version,
        },
      })
    );

    if (
      activePickTour &&
      (orderType === ORDER_TYPE_COMMERCIAL || orderType === ORDER_TYPE_COMMERCIAL_EP) &&
      (paginationData.isPaginated
        ? paginationData.totalItemsSkipped > 0
        : activePickTour.picks.some(pick => pick.isSkipped))
    ) {
      if (orderType === ORDER_TYPE_COMMERCIAL_EP) {
        dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_COMMERCIAL_SKIP }));
        return;
      }
      dispatch(skipPickTour());
    } else {
      dispatch(
        updatePickTour({
          updateType,
          updateStatus: determinePickTourStatus(updateType),
        })
      );
    }
  };

  const returnToOrderList = () => {
    dispatch(setAppPage({ name: APP_PAGE_ORDER_LIST }));
    dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_PICK_TOUR }));
  };

  const handleHeaderButtonClick = () => {
    switch (pickTourPage) {
      case PICK_TOUR_PAGE_PICK_TOUR: {
        if (isTourComplete) {
          dispatch(createToast({ type: 'error', message: t('ERRORS.ALL_ITEMS_PICKED') }));
        } else {
          dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_EXIT }));
        }
        break;
      }
      case PICK_TOUR_PAGE_COMMERCIAL_SKIP: {
        dispatch(createToast({ type: 'error', message: t('ERRORS.CONFIRM_SKIPPED') }));
        break;
      }
      case PICK_TOUR_PAGE_COMPLETE: {
        returnToOrderList();
        break;
      }
      case PICK_TOUR_PAGE_EXIT: {
        dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_PICK_TOUR }));
        break;
      }
      case PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED: {
        dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_PICK_TOUR }));
        break;
      }
      case PICK_TOUR_PAGE_EXIT_REVIEW: {
        returnToOrderList();
        break;
      }
      default: {
        break;
      }
    }
  };

  const activePick = activePickTour && activePickTour.picks.find(pick => generatePickID(pick) === activePickID);

  const filterPicksForListView = () => {
    if (isTourComplete && pickTourPage !== PICK_TOUR_PAGE_COMPLETE && !paginationData.isPaginated) {
      return activePickTour?.picks.some(pick => pick.isSkipped)
        ? activePickTour.picks.filter(pick => pick.isSkipped)
        : activePickTour && activePickTour.picks;
    }

    if (pickTourPage === PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED) {
      return (
        activePickTour?.picks
          .filter(pick => pick.pickedQuantity === calculateAmountToPick(pick) || pick.isSkipped)
          // eslint-disable-next-line no-nested-ternary
          .sort((a: Pick, b: Pick) => (a.isSkipped === b.isSkipped ? 0 : b.isSkipped ? -1 : 1))
      );
    }
    if (pickTourPage === PICK_TOUR_PAGE_EXIT_REVIEW) {
      return activePickTour?.picks.map(pick =>
        pick.pickedQuantity < calculateAmountToPick(pick) && !pick.isSkipped ? { ...pick, pickedQuantity: 0 } : pick
      );
    }
    return activePickTour?.picks;
  };

  const getPickedQuantity = () => {
    if (activePickTour) {
      const skips = activePickTour.picks.reduce((prev, curr) => {
        return prev + (curr.isSkipped ? curr.totalQuantity - curr.pickedQuantity : 0);
      }, 0);
      if ([PICK_TOUR_PAGE_PICK_TOUR, PICK_TOUR_PAGE_EXIT].includes(pickTourPage) || orderType === ORDER_TYPE_RECALL) {
        if ([PICK_TOUR_PAGE_EXIT_REVIEW].includes(pickTourPage)) {
          return (
            skips +
            calculateQuantitiesForExitReview(activePickTour) +
            (paginationData.isPaginated ? paginationData.totalItemsPicked - paginationData.initialPageItemsPicked : 0)
          );
        }
        return (
          skips +
          calculatePickedQuantity(activePickTour) +
          (paginationData.isPaginated ? paginationData.totalItemsPicked - paginationData.initialPageItemsPicked : 0)
        );
      }

      return (
        skips +
        calculateResolvedPickPickedQuantity(activePickTour) +
        (paginationData.isPaginated ? paginationData.totalItemsPicked - paginationData.initialPageItemsPicked : 0)
      );
    }
    return 0;
  };

  const renderHeader = () => {
    if (activePickTour && hasJustScanned !== null) {
      if (pickTourPage === PICK_TOUR_PAGE_ITEM_VALIDATION) {
        return (
          <div className={styles.leftContainer}>
            <Logo />
            <h1 className={mergeClassNames(styles.title, currentLanguage === 'es-MX' && styles.titleSpanish)}>
              {t('HEADER.ACTIVE_PICK_TOUR_ORDER_VALIDATION')}
            </h1>
          </div>
        );
      }
      if (manualEntryStatus !== 'closed') {
        return (
          <>
            <h1 className={mergeClassNames(styles.title, currentLanguage === 'es-MX' && styles.titleSpanish)}>
              {// eslint-disable-next-line no-nested-ternary
              manualEntryStatus === 'labels'
                ? t('HEADER.NUMBER_OF_CONTAINERS')
                : manualEntryStatus === 'adjustRecall'
                ? t('HEADER.ADJUST_RECALL_QUANTITY')
                : activePick && activePick.description}
            </h1>
            <IconButton
              icon={<XIcon />}
              onClick={() => setManualEntryStatus('closed')}
              data-cy="manual-entry-close-button"
            />
          </>
        );
      }
      return (
        <>
          <div className={styles.leftContainer}>
            <Logo />
            <h1 className={mergeClassNames(styles.title, currentLanguage === 'es-MX' && styles.titleSpanish)}>
              {t('HEADER.ACTIVE_PICK_TOUR')}
            </h1>
          </div>
          <span className={styles.divider} />
          <div className={styles.storeAndEmplooyeData}>
            <p>{`${t('UTILS.STORE')}#: ${String(Number(pickerStore)).padStart(5, '0')}`}</p>
            <p>{trimUserName(employee.name || '', innerWidth)}</p>
          </div>
          <MediaQuery maxWidth={MQ_MAX_WIDTH}>
            <TourQuantityBox
              pickedQuantity={getPickedQuantity()}
              totalQuantity={
                paginationData.isPaginated ? paginationData.totalItems : calculateTotalQuantity(activePickTour)
              }
              hasJustScanned={hasJustScanned}
              currentLanguage={currentLanguage}
            />
          </MediaQuery>

          <IconButton
            icon={pickTourPage === PICK_TOUR_PAGE_EXIT ? <ArrowBackIcon /> : <XIcon />}
            onClick={handleHeaderButtonClick}
            data-cy="pick-tour-header-button"
          />
        </>
      );
    }
    return null;
  };

  const handlePageChange = (page: number) => {
    // This just checks for Overstock for now but may need to check other order types in the future
    if (
      activePickTour &&
      (orderType === ORDER_TYPE_OVERSTOCK || orderType === ORDER_TYPE_RECALL) &&
      (isTourComplete || pickTourPage === PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED)
    ) {
      dispatch(
        getPickTourItems({
          isPickTourCreation: false,
          page: page - 1,
          pickTourID: activePickTour.id,
          orderType,
        })
      );
    }
  };

  useEffect(() => {
    if (orderType === ORDER_TYPE_RECALL && activePick && activePick.locations.length > 1) {
      dispatch(createToast({ type: 'information', message: `${t(`ACTIVE_PICK_TOUR.RECALL_ACKNOWLEDGEMENT`)}` }));
    }
  }, [orderType, dispatch, t, activePick]);

  useEffect(() => {
    if (orderType === ORDER_TYPE_RECALL && activePick && activePick.locations.length > 1) {
      dispatch(createToast({ type: 'information', message: `${t(`ACTIVE_PICK_TOUR.RECALL_ACKNOWLEDGEMENT`)}` }));
    }
  }, [orderType, dispatch, t, activePick]);

  useEffect(() => {
    if (!isTourStarted && isTourMixedValidation) {
      dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_ITEM_VALIDATION }));
    }
  }, [isTourMixedValidation, dispatch, isTourStarted]);

  useEffect(() => {
    if (isFullyPickedOrSkipped) {
      dispatch(
        createToast({
          type: 'information',
          message: endOfTourTranslation,
        })
      );
      setIsTourComplete(true);
    }
  }, [isFullyPickedOrSkipped, endOfTourTranslation, dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Reassigning event.returnValue is necessary for Chrome to prevent an unload
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    setIsTourStarted(true);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return activePickTour && activePickID && hasJustScanned !== null ? (
    <>
      <Header>
        <div className={styles.headerContainer}>{renderHeader()}</div>
      </Header>
      <Main className={styles.main}>
        <Switch currentPageName={pickTourPage}>
          <Page name={PICK_TOUR_PAGE_ITEM_VALIDATION}>
            <ItemValidation onContinue={() => dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_PICK_TOUR }))} />
          </Page>
          <Page name={PICK_TOUR_PAGE_PICK_TOUR}>
            <ActivePickTour
              manualEntryStatus={manualEntryStatus}
              setManualEntryStatus={setManualEntryStatus}
              pickTour={activePickTour}
              activePickID={activePickID}
              paginationData={paginationData}
              isTourComplete={isTourComplete}
              filteredPickList={filterPicksForListView() || activePickTour.picks}
              closePickTour={closePickTour}
              setIsTourComplete={setIsTourComplete}
              handlePageChange={handlePageChange}
              currentLanguage={currentLanguage}
              containerInputValue={containerInputValue}
              setContainerInputValue={setContainerInputValue}
            />
          </Page>
          <Page name={PICK_TOUR_PAGE_COMMERCIAL_SKIP}>
            <CommercialSkipFlow />
          </Page>
          <Page name={PICK_TOUR_PAGE_COMPLETE}>
            <PickTourComplete
              activePickTour={activePickTour}
              paginationData={paginationData}
              returnToOrderList={returnToOrderList}
              printLocationReport={() => dispatch(printLocationReport({ pickTourId: activePickTour.id }))}
              printDiscrepancyReport={() =>
                dispatch(
                  printDiscrepancyReport({
                    storeId: activePick && activePick.destination ? activePick.destination : '',
                  })
                )
              }
              printRecallReport={() =>
                dispatch(
                  printRecallReport({ pickTourId: activePickTour.id, labelQty: parseInt(containerInputValue, 10) })
                )
              }
              currentLanguage={currentLanguage}
              labelsToPrint={parseInt(containerInputValue, 10)}
            />
          </Page>
          <Page name={PICK_TOUR_PAGE_EXIT}>
            <ExitPickTour determinePickTourStatus={determinePickTourStatus} currentLanguage={currentLanguage} />
          </Page>
          {/* TODO: Although this page isn't being used currently, leaving code in place for easy implemention at a future date */}
          <Page name={PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED}>
            <ConfirmStaged
              pickTour={activePickTour}
              paginationData={paginationData}
              picks={filterPicksForListView() || activePickTour.picks}
              onPageChange={handlePageChange}
              closePickTour={closePickTour}
            />
          </Page>
          <Page name={PICK_TOUR_PAGE_UPDATE_FAILED}>
            <ConfirmationScreen
              confirmationText={t('ERRORS.UPDATE_ITEM_FAILED.UNKNOWN_ERROR')}
              confirmationButtonText={t('ERRORS.UPDATE_ITEM_FAILED.RETURN')}
              handleConfirm={() => closePickTour('exit')}
            />
          </Page>
          <Page name={PICK_TOUR_PAGE_EXIT_REVIEW}>
            <PickTourExitReview
              pickTour={activePickTour}
              paginationData={paginationData}
              returnToOrderList={returnToOrderList}
              currentLanguage={currentLanguage}
              orderType={orderType}
            />
          </Page>
        </Switch>
        <MediaQuery minWidth={MQ_MIN_WIDTH}>
          <Aside
            pickTourPage={pickTourPage}
            picks={filterPicksForListView() || activePickTour.picks}
            pickedQuantity={getPickedQuantity()}
            totalQuantity={
              paginationData.isPaginated ? paginationData.totalItems : calculateTotalQuantity(activePickTour)
            }
            hasJustScanned={hasJustScanned}
            paginationData={paginationData}
            isTourComplete={isTourComplete}
            printPickList={() => dispatch(printPickList({ activePickTour }))}
            onPageChange={handlePageChange}
            currentLanguage={currentLanguage}
          />
        </MediaQuery>
      </Main>
    </>
  ) : null;
};

export default PickTourFlow;
